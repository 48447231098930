import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";
import { setUrl } from "@/utils/common";
// 利益汇总 卡片数据
export const getProfitsCardList = (data:any): Promise<AxiosResponse<object>> =>{
    let url = setUrl(`/agent-api/api/profits_statistics?`,data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 利益汇总 图表数据
export const getProfitsChartList = (data:any): Promise<AxiosResponse<object>> =>{
    let url = setUrl(`/agent-api/api/profits_statis_echarts?`,data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 利益汇总订单数据
export const getProfitsOrderList = (data:any): Promise<AxiosResponse<object>> =>{
    let url = setUrl(`/agent-api/api/profits_order_data?`,data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}





