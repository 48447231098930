
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import dayjs,{ Dayjs } from 'dayjs';
import { defineComponent, onMounted, reactive, ref, toRefs,Ref, onBeforeMount } from "vue";
import * as echarts from 'echarts';
import { getProfitsCardList,getProfitsChartList,getProfitsOrderList } from '@/api/saleManage/profit';
import  DateUtil,{getPickerTime}  from '@/utils/common';
import { getOptionData } from '@/api/saleManage';
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
export default defineComponent({
    name: "profitSummary",
    components:{
        breadcrumb
    },
    setup(){
        const isOpen = ref<boolean>(false)
        // 面包屑
        const breadData = ref<any []>([
            {title:'首页',path:'#/'},
            {title:'销售管理',path:'/index/saleManage'},
            {title:'利润汇总',path:'#/'},
        ])

        // 下拉数据
        const optionVal = ref<Array<any>>([])
        // picker
        const pickerFoo = ref<Array<any>>([
            {title:'清空',type:'0'},
            {title:'今天',type:'1'},
            {title:'本周',type:'2'},
            {title:'当月',type:'3'},
            {title:'今年',type:'4'},
            {title:'去年',type:'5'}
        ])
        const time = ref<Dayjs []>()
        const timeDate = reactive({
            type:'',
            time:{}
        })
        // card
        const cardList = reactive({
            list:[
                {title:'总收益（二维码+自然流量）'},
                {title:'二维码'},
                {title:'自然流量'},
            ],
            cardData:{}
        })
        // chart
        const xData = ref<Array<any>>([])
        const yData = ref<Array<any>>([])

        const query = reactive({
            team:'',
            checked:false
        })
        // table
        const dataSource = ref<Array<any>>([])
        const columns =  [
          { title: '时间',dataIndex: 'date_time',key: 'date_time',fixed:true},
          { title: '总收益',dataIndex: 'totle_pro', key: 'totle_pro'},
          { title: '二维码',dataIndex: 'code',key: 'code',width:'40%',},
          { title: '自然流量', dataIndex: 'flow', key: 'flow', width:'25%'},
        ]
        onBeforeMount(()=>{
            getTeamData()
            setPicker('3')
            getCardData()
            getChartsData()
            getTableData()
        })

        // picker底部点击事件
        const setPicker = (type:string):void =>{
            timeDate.type = type;
            switch (type) {
                case '0':
                    time.value = [];
                    break;
                case '1':
                    time.value = [dayjs(),dayjs()];
                    break;
                case '2':
                    time.value = [dayjs(DateUtil.DateUtil.getStartDayOfWeek()),dayjs(DateUtil.DateUtil.getEndDayOfWeek())];
                    break;
                case '3':
                    time.value = [dayjs(DateUtil.DateUtil.getStartDayOfMonth()),dayjs(DateUtil.DateUtil.getEndDayOfMonth())];
                    break;
                case '4':
                    time.value = [dayjs(DateUtil.DateUtil.getYear('s',0)),dayjs(DateUtil.DateUtil.getYear('e',0))];
                    break;
                case '5':
                    time.value = [dayjs(DateUtil.DateUtil.getYear('s',-1)),dayjs(DateUtil.DateUtil.getYear('e',-1))];
                    break;    
                default:
                    break;
                
            }
            
            timeDate.time = getPickerTime(time.value,0)
        }


        const rightInit = ()=>{
            var myChart = echarts.init((document as any).getElementById('right'));
            let data: Array<string> = ["总计", "二维码","自然流量"];
            let color:Array<string> = ['#5081FF','#CA5A40','#D0A537']

            let legendData: Array<object> = [];
            let series: Array<object> = []
            for (let i = 0; i < data.length; i++) {
                legendData.push({
                    name: data[i],
                    
                    itemStyle: {
                        opacity: 0,
                    },
                    lineStyle: {
                        width: 5,
                    }
                });
                series.push({
                    name: data[i],
                    type: 'line',
                    // stack: 'Total',
                    showSymbol: false,
                    symbolSize: 10,
                    symbol: 'circle',
                    smooth: true,
                    data:yData.value[i],
                    lineStyle:{
                        color:color[i]
                    }
                })
            }
            
            var option;
            option = {
                color:color,
                backgroundColor:'#282976',
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: legendData,
                    
                    textStyle:{
                        color:'#7471A7',
                        borderRadius:5
                    },
                    top:20,
                    right:20,
                    // icon: "circle",
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xData.value,
                    axisLabel:{
                        show:true,
                        interval:0,  
                        rotate:40,
                        textStyle:{
                            color: '#4A4BD1'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine:{
                        lineStyle:{
                            color:'#30328D'
                        }
                    },
                    axisLabel:{
                        show:true,
                        textStyle:{
                            color: '#4A4BD1'
                        }
                    }
                },
                series: series
            };
            myChart.setOption(option);
        }
        
        // 卡片list
        const getCardData = ():void =>{
            let data = {
                type:timeDate.type,
                start_time:(timeDate.time as any).start,
                end_time:(timeDate.time as any).end,
                agent_id:query.team
            }            
            getProfitsCardList(data).then((res:any)=>{
                if(res.code == 200){
                    cardList.cardData = res.data
                }
                
            })
        }
        // 图表数据
        const getChartsData = ():void=>{
            let data = {
                type:timeDate.type,
                start_time:(timeDate.time as any).start,
                end_time:(timeDate.time as any).end,
                agent_id:query.team
            }  
            getProfitsChartList(data).then((res:any)=>{
                if(res.code == 200){
                    xData.value = res.data.data_xtime;
                    yData.value = res.data.data_echarts;
                    rightInit()
                }
            })
        }
        // 表格数据
        const getTableData = ():void=>{
            let data = {
                type:timeDate.type,
                start_time:(timeDate.time as any).start,
                end_time:(timeDate.time as any).end,
                agent_id:query.team
            } 
            getProfitsOrderList(data).then((res:any)=>{
                if(res.code == 200){
                    dataSource.value = res.data.data_order_totle;
                }
            })
        }
        // 时间框的回调
        const timeChange = ():void=>{
            timeDate.type = '6';
            timeDate.time = getPickerTime(time.value,0)
        }
        const confirm = ():void =>{
            isOpen.value = false;
            getCardData()
            getChartsData()
            getTableData()
        }

         // 获取团队
        const getTeamData = ():void=>{
            getOptionData().then((res:any)=>{
                if(res.code == 200){
                console.log(res.data);
                optionVal.value = res.data;
                }
            })
        }
        const selectChang = (val:any):void =>{
            getCardData()
            getChartsData()
            getTableData()
        }
        // 展开时间框
        const pickerFocus = ()=>{
            isOpen.value = true;
        }
        // 收起时间框
        const openChange = (status:boolean):void =>{
            console.log(status);
            isOpen.value = status;
        }

        return{
            locale,
            dataSource,
            columns,
            pickerFoo,
            time,
            optionVal,
            breadData,
            isOpen,
            ...toRefs(query),
            ...toRefs(cardList),
            setPicker,
            confirm,
            timeChange,
            selectChang,
            pickerFocus,
            openChange
        }
    }  
})
